<script setup lang="ts">

  // Idioma
  const { locale } = useI18n()

  // Strapi
  const strapiData = await getStrapiData('global', {
    populate: [
      "footer.keywords",
      "footer.tituloPrivacidad",
      "footer.privacidadLinks",
      "footer.tituloInformacion",
      "footer.infoLinks",
      "footer.tituloHoteleros",
      "footer.subtituloHoteleros",
      "footer.buttonHotelero",
      "footer.tituloAgencia",
      "footer.subtituloAgencia",
      "footer.buttonAgencia",
      "footer.tituloDestinos",
      "footer.linkDestinos",
      "footer.tituloSiguenos",
      "footer.redes.img",
      "footer.tituloApp",
      "footer.buttonsApp.img"
    ],
    locale: locale.value
  })
  const strapiContent = strapiData?.footer

</script>
<template>
  <div class="z-40 w-full bg-gray-950 text-white dark:bg-gray-950 dark:border-t dark:border-t-gray-800">
    <div :class="`mx-auto max-w-screen-2xl py-12 flex flex-col gap-6 px-5`">
      <div class="flex flex-wrap gap-8">
        <div v-for="item in strapiContent?.keywords">
          <UButton
            variant="outline"
            color="gray"
            size="xl"
            :label="item?.label"
            :to="getButtonUrl(item)"
            target="_blank"
          />
        </div>
      </div>
      <UDivider class="py-4" 
        :ui="{
          border: {
            base: 'flex border-gray-800'
          }
        }"
      />
      <div class="grid gap-8 md:gap-2 grid-cols-2 md:grid-cols-4">
        <div>
          <p class="font-semibold mb-2">{{ strapiContent?.tituloPrivacidad }}</p>
          <ul class="flex flex-col gap-1">
            <li v-for="item in strapiContent?.privacidadLinks">
              <NuxtLink :aria-label="item.label" :to="getButtonUrl(item)" target="_blank">
                <p class="text-gray-400">{{ item.label }}</p>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div>
          <p class="font-semibold mb-2">{{ strapiContent?.tituloInformacion }}</p>
          <ul class="flex flex-col gap-1">
            <li v-for="item in strapiContent?.infoLinks">
              <NuxtLink :aria-label="item.label" :to="getButtonUrl(item)" target="_blank">
                <p class="text-gray-400">{{ item.label }}</p>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div>
          <div class="mb-8">
            <p class="font-semibold mb-2">{{ strapiContent?.tituloHoteleros }}</p>
            <p class="text-gray-400">{{ strapiContent?.subtituloHoteleros }}</p>
            <UButton size="md" color="black" variant="outline" :ui="{ rounded: 'rounded-full' }" :aria-label="strapiContent?.buttonHotelero?.label" class="my-2" :to="getButtonUrl(strapiContent?.buttonHotelero)" target="_blank">{{ strapiContent?.buttonHotelero?.label }}</UButton>
          </div>
          <div>
            <p class="font-semibold mb-2">{{ strapiContent?.tituloAgencia }}</p>
            <p class="text-gray-400">{{ strapiContent?.subtituloAgencia }}</p>
            <UButton size="md" color="black" variant="outline" :ui="{ rounded: 'rounded-full' }" :aria-label="strapiContent?.buttonAgencia?.label" class="my-2" :to="getButtonUrl(strapiContent?.buttonAgencia)" target="_blank">{{ strapiContent?.buttonAgencia?.label }}</UButton>
          </div>
        </div>
        <div>
          <p class="font-semibold mb-2">{{ strapiContent?.tituloDestinos }}</p>
          <ul class="grid grid-cols-2 gap-x-4 gap-y-1 w-full text-gray-400">
            <li v-for="item in strapiContent?.linkDestinos" class="truncate">
              <NuxtLink :aria-label="item.label" :to="getButtonUrl(item)" target="_self">
                {{ item.label }}
              </NuxtLink> 
            </li>
          </ul>
        </div>
      </div>
      <UDivider class="py-4" 
        :ui="{
          border: {
            base: 'flex border-gray-800'
          }
        }"
      />
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-0">
        <div>
          <p class="font-semibold mb-4">{{ strapiContent?.tituloSiguenos }}</p>
          <ul class="flex gap-6 items-center">
            <li v-for="item in strapiContent?.redes">
              <NuxtLink :aria-label="item.label" :to="getButtonUrl(item)" target="_blank">
                <NuxtImg :provider="item?.img?.data?.attributes?.provider" class="invert" loading="lazy" :aria-label="item.label" :src="item?.img?.data?.attributes?.url" width="25"/>
              </NuxtLink>
            </li>
          </ul>
        </div>
        <div>
          <p class="font-semibold mb-4">{{ strapiContent?.tituloApp }}</p>
          <ul class="flex gap-6">
            <li v-for="item in strapiContent?.buttonsApp">
              <NuxtLink :aria-label="item.label" :to="getButtonUrl(item)" target="_blank">
                <NuxtImg :provider="item?.img?.data?.attributes?.provider" class="invert" loading="lazy" :aria-label="item.label" :src="item?.img?.data?.attributes?.url" width="100"/>
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>    
  </div>
</template>