<script lang="ts" setup>

  // Head
  useHead({
    link: [
      {
        rel: 'icon',
        href: '/favicon.ico'
      }
    ],
    meta: [
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, maximum-scale=1'
      }
    ]
  })

  // Iniciar dia
  const selectedDiaStore = useSelectedDayStore()
  selectedDiaStore.init()

</script>

<template>
  <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#ff620a 0%,#C40058 100%)" />
	<div class="flex flex-col justify-center items-center">
		<Header />
		  <slot />
		<Footer />
	</div>
</template>
